import styled from 'styled-components';

import { GlassTilePropsType } from './models.d';

export const StyledGlassTile = styled.section<GlassTilePropsType>`
  position: relative;
  display: inline-block;
  padding: 40px 20px;

  ${({ blurred }) =>
    blurred
      ? `
          backdrop-filter: blur(32px);
        `
      : ''}

  &[data-glass-variant='light'] {
    background: linear-gradient(
      136.89deg,
      rgba(255, 255, 255, 75%) 0.04%,
      rgba(255, 255, 255, 50%) 75.83%
    );
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-05);

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: rgba(255, 255, 255, 90%);
      box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-10);
    }
  }

  &[data-glass-variant='dark'] {
    background: linear-gradient(
      99.13deg,
      rgba(21, 49, 65, 75%) -14.09%,
      rgba(16, 24, 40, 92%) 29.2%,
      rgba(16, 24, 40, 95%) 48.51%,
      rgba(16, 24, 40, 92%) 68.12%,
      rgba(21, 22, 44, 80%) 103.45%
    );
    border: 1px solid var(--c-gray-800);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--box-shadow-primary) rgba(var(--c-black), 0.15);

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: linear-gradient(
        99.13deg,
        rgba(21, 49, 65, 75%) -14.09%,
        rgba(16, 24, 40, 92%) 29.2%,
        rgba(16, 24, 40, 95%) 48.51%,
        rgba(16, 24, 40, 92%) 68.12%,
        rgba(21, 22, 44, 80%) 103.45%
      );
      box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-10);
    }
  }

  &[data-glass-variant='light-feature'] {
    background: linear-gradient(
      136.89deg,
      rgba(255, 255, 255, 75%) 0.04%,
      rgba(255, 255, 255, 50%) 75.83%
    );
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-05);

    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: rgba(255, 255, 255, 90%);
      box-shadow: var(--box-shadow-primary) var(--c-opacity-blue-10);
    }
  }
`;
