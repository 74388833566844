import styled from 'styled-components';
import { breakpoint } from 'styles';

import { StyledButton } from 'components/atoms/Button/Button.styles';
import { StyledGlassTile } from 'components/atoms/GlassTile/GlassTile.styles';

export const StyledPolicyWrapper = styled.div`
  --indentation: 35px;

  place-self: center;
  width: 100%;
  max-width: 1040px;
  padding-bottom: var(--gap-xxxxxl);

  ${breakpoint.xl} {
    margin-left: 60px;
  }

  ${breakpoint.xxl} {
    max-width: 1120px;
    margin-left: 0;
  }

  .link {
    text-decoration: underline;
  }

  ul.unordered-list {
    padding-left: var(--indentation);
    margin: 10px 0;
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ol.ordered-list {
    padding-left: var(--indentation);
    margin: 10px 0;
    list-style: decimal;

    ol.ordered-list {
      padding-left: var(--indentation);
      margin: 10px 0;
      list-style: lower-alpha;

      ol.ordered-list {
        padding-left: var(--indentation);
        margin: 10px 0;
        list-style: upper-roman;
      }
    }
  }
`;

export const StyledPolicyHeader = styled.div`
  --icon-size: 248px;

  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 98%);
  background-color: var(--c-white);

  ${breakpoint.md} {
    --icon-size: 412px;
  }

  ${breakpoint.lg} {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 98%);
  }

  ${breakpoint.xl} {
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 98%);
  }

  .page-name {
    position: relative;
    z-index: var(--z-index-zero);
    place-self: center;
    width: 100%;
    max-width: 1040px;
    letter-spacing: -0.02em;

    ${breakpoint.xl} {
      margin-left: 60px;
    }

    ${breakpoint.xxl} {
      max-width: 1120px;
      margin-left: 0;
    }
  }

  ${StyledButton} {
    position: absolute;
    top: 104px;
    left: 0;

    ${breakpoint.lg} {
      top: 80px;
    }

    ${breakpoint.xl} {
      left: 80px;
    }
  }

  .policy-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-underground);
    width: var(--icon-size);
    height: var(--icon-size);
    transform: translateX(64px);

    ${breakpoint.md} {
      transform: translateX(32px);
    }

    ${breakpoint.lg} {
      right: 160px;
    }

    path {
      fill: var(--c-gray-50);
    }
  }
`;

export const StyledPolicyLinks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--gap-lg);
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--gap-xxxxxl);

  ${breakpoint.md} {
    flex-direction: row;
    row-gap: 0;
    column-gap: var(--gap-lg);
    align-items: stretch;
  }

  ${breakpoint.lg} {
    padding: 0 var(--gap-xxxxxl);
  }

  ${StyledGlassTile} {
    width: 100%;
    max-width: 405px;
    padding: var(--gap-xxs);
  }

  & > svg {
    width: 32px;
    height: 32px;

    ${breakpoint.lg} {
      width: 48px;
      height: 48px;
    }
  }
`;
