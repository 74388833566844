import React from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';

import Button from 'components/atoms/Button';
import GlassTile from 'components/atoms/GlassTile';
import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import Layout from 'components/templates/Layout';
import {
  StyledPolicyHeader,
  StyledPolicyLinks,
  StyledPolicyWrapper,
} from 'components/templates/StyledPolicyWrapper/StyledPolicyWrapper';

import { useScreenService } from 'hooks/useScreenService';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Typography fontWeight={400} size={18} asEl="p" color="gray-900">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Typography
        size={{ base: 24, md: 30, lg: 36 }}
        fontWeight={500}
        color="gray-900"
        padding={{ top: { base: 32, xl: 80 }, bottom: 24 }}
        asEl="h2"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Typography
        size={{ base: 20, md: 24, lg: 30 }}
        fontWeight={500}
        color="gray-900"
        padding={{ bottom: 16, top: 16 }}
        asEl="h3"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Typography
        size={{ base: 18, md: 20, lg: 24 }}
        fontWeight={500}
        color="gray-900"
        padding={{ bottom: 16, top: 16 }}
        asEl="h4"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.OL_LIST]: (_, children) => <ol className="ordered-list">{children}</ol>,
    [BLOCKS.UL_LIST]: (_, children) => <ul className="unordered-list">{children}</ul>,
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <Typography padding={{ top: 24 }} asEl="li">
        {children}
      </Typography>
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a target="_blank" className="link" data-target="hover" href={data.uri} rel="noreferrer">
        {children}
      </a>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Typography size={18} fontWeight={500} color="gray-900" asEl="span">
        {text}
      </Typography>
    ),
  },
};

const PolicyPage = ({
  data: {
    contentfulPolicyPage: { pageName, content, icon, backButton, links, seo },
  },
}: PolicyPageTypes.ServicesDetailPageProps) => {
  const { isMobile, isLgUp, isXlUp } = useScreenService();

  return (
    <Layout seo={seo} className="policy-page" isFooterTop={false} transparentHeader={false}>
      <StyledPolicyHeader>
        <Container contentWidth={1440} noPadding={isXlUp}>
          <Container contentWidth={1440} noPadding>
            <Typography
              className="page-name"
              asEl="h1"
              fontWeight={500}
              color="gray-900"
              size={{ base: 36, md: 48, lg: 60 }}
              lineHeight={1.2}
              padding={{ top: { base: 200, md: 232, lg: 208 }, bottom: { base: 48, md: 80 } }}
            >
              {pageName}
            </Typography>
            <Button
              {...backButton}
              typography={{
                size: 16,
                fontWeight: 500,
                padding: { top: 10, bottom: 10, left: 18, right: 18 },
              }}
              icon={backButton?.icon && { name: backButton.icon.name, position: 'left' }}
              onClick={() => window.history.back()}
            />
            <Icon className="policy-icon" renderComponent {...icon} />
          </Container>
        </Container>
      </StyledPolicyHeader>
      <Container contentWidth={1440}>
        <StyledPolicyWrapper className="policy-content">
          {renderRichText(content, options)}
        </StyledPolicyWrapper>
        {links ? (
          <StyledPolicyLinks>
            {links.map(({ icon: localIcon, pageName: name, slug, readButton }, idx) => (
              <GlassTile data-glass-variant="light" key={`glass-tile-${name}-${idx}`}>
                <Icon {...localIcon} width={isLgUp ? '48' : '32'} height={isLgUp ? '48' : '32'} />
                <Typography
                  fontWeight={500}
                  lineHeight={1.5}
                  color="gray-800"
                  size={{ base: 20, lg: 24 }}
                  padding={{ top: { base: 8, lg: 32 }, bottom: 16 }}
                >
                  {name}
                </Typography>
                <Button
                  {...readButton}
                  typography={{
                    fontWeight: 500,
                    size: 14,
                    padding: { top: 10, right: 16, bottom: 10, left: 16 },
                  }}
                  link={slug}
                  icon={{
                    name: !isMobile && idx === 0 ? 'common-arrow-left' : 'common-arrow-right-black',
                    position: !isMobile && idx === 0 ? 'left' : 'right',
                  }}
                />
              </GlassTile>
            ))}
          </StyledPolicyLinks>
        ) : null}
      </Container>
    </Layout>
  );
};

export default PolicyPage;

export const query = graphql`
  query PolicyPageQuery($slug: String) {
    contentfulPolicyPage(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }
      pageName
      content {
        raw
      }
      icon {
        ...IconFragment
      }
      backButton {
        ...ButtonFragment
      }
      links {
        pageName
        slug
        icon {
          ...IconFragment
        }
        readButton {
          ...ButtonFragment
        }
      }
    }
  }
`;
