import React, { forwardRef } from 'react';

import { StyledGlassTile } from './GlassTile.styles';

import { GlassTilePropsType } from './models.d';

const GlassTile = forwardRef(
  (
    { children, className, blurred, ...delegated }: GlassTilePropsType,
    ref: React.Ref<HTMLElement>
  ) => (
    <StyledGlassTile
      ref={ref}
      className={className}
      data-testid="glass-tile"
      {...{ ...delegated, blurred }}
    >
      {children}
    </StyledGlassTile>
  )
);

export default GlassTile;
